<template>
  <el-container class="CodeRecords">
    <el-header>
      <h1>兑换码记录</h1>
    </el-header>
    <el-main>
      <el-input
        v-model="searchQuery"
        placeholder="搜索卡密、制卡人或使用用户"
        @input="onSearch"
        clearable
        prefix-icon="el-icon-search"
        style="margin-bottom: 20px;"
      ></el-input>

      <el-table
        :data="records"
        style="width: 100%;"
        :default-sort="{ prop: 'creationTime', order: 'descending' }"
      >
        <el-table-column prop="code" label="兑换码" width="400"></el-table-column>
        <el-table-column prop="category" label="类别" width="100"></el-table-column>
        <el-table-column prop="game" label="所属游戏" width="150"></el-table-column>
        <el-table-column prop="status" label="状态" width="120"></el-table-column>
        <el-table-column prop="creator" label="制卡人" width="120"></el-table-column>
        <el-table-column prop="creationTime" label="制卡时间" sortable width="180"></el-table-column>
        <el-table-column prop="usageTime" label="使用时间" sortable width="180"></el-table-column>
        <el-table-column v-if="owner === '0'" prop="records" label="备注" sortable width="400"></el-table-column>
        <el-table-column prop="user" label="使用用户" width="120"></el-table-column>
        <el-table-column label="操作" width="150">
          <template v-slot="scope">
            <el-button
              v-if="scope.row.user"
              @click="toggleBan(scope.row)"
              type="primary"
              size="small"
            >
              {{ scope.row.status === '被封禁' ? '解封' : '封禁' }}
            </el-button>
            <el-button
              v-else
              @click="deleteRecord(scope.row)"
              type="danger"
              size="small"
            >
              删除
            </el-button>
            <el-button
              v-if="scope.row.category === '豪华版'"
              @click="upgradeVersion(scope.row)"
              type="warning"
              size="small"
            >
              升级
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 修复：v-else放在v-if的后面 -->
      <div v-if="records.length === 0">
        <el-empty description="没有记录可显示"></el-empty>
      </div>

      <el-pagination
        background
        layout="prev, pager, next, jumper, total"
        :total="totalCount"
        :page-size="pageSize"
        v-model:current-page="currentPage"
        @current-change="onPageChange"
        style="margin-top: 20px; text-align: center;"
      ></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'CodeRecords',
  data() {
    return {
      records: [],         // 存储分页后的数据
      searchQuery: '',     // 搜索查询词
      currentPage: 1,      // 当前页
      pageSize: 10,        // 每页显示的记录数
      totalCount: 0,       // 总记录数
    };
  },
  setup() {
    const store = useStore();
    const owner = computed(() => store.state.user.owner);  // 获取是否为owner
    return { owner };
  },
  created() {
    this.fetchRecords();  // 初次加载数据
  },
  methods: {
    // 获取数据时直接使用后端返回的currentPage
    async fetchRecords() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=generateRecords', {
          token,
          search: this.searchQuery,
          page: this.currentPage,
          pageSize: this.pageSize,
        });
        
        if (response.data.code === 200) {
          // 确保 totalCount 是数字
          this.records = response.data.records || [];
          this.totalCount = response.data.totalCount || 0;  // 确保 totalCount 是数字
          this.currentPage = response.data.currentPage || 1;  // 使用后端返回的currentPage
        } else {
          ElMessage.error('加载数据失败');
        }
      } catch (error) {
        console.error('Error fetching records:', error);
        this.records = [];
        ElMessage.error('加载数据失败');
      }
    },
    async toggleBan(record) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=toggleBan', { token, keysr: record.code });
        if (response.data.success) {
          record.status = response.data.newStatus;
          ElMessage.success('状态已更新');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        ElMessage.error('操作失败，请稍后重试');
      }
    },
    async deleteRecord(record) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deleteRecord', { token, keysr: record.code });
        if (response.data.success) {
          this.records = this.records.filter(r => r.code !== record.code);
          ElMessage.success('记录已删除');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        ElMessage.error('删除失败，请稍后重试');
      }
    },
    async upgradeVersion(record) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=upgradeVersion', { token, keysr: record.code });
        if (response.data.success) {
          record.category = '终极版';
          ElMessage.success('版本已升级');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        ElMessage.error('升级失败，请稍后重试');
      }
    },
    // 处理分页变更
    onPageChange(page) {
      this.currentPage = page;
      this.fetchRecords();  // 切换页码时重新加载数据
    },
    // 处理搜索
    onSearch() {
      this.currentPage = 1;  // 重置为第一页
      this.fetchRecords();  // 搜索时重新加载数据
    }
  }
};
</script>

<style scoped>
.CodeRecords {
  padding: 20px;
}

.el-table th, .el-table td {
  white-space: nowrap;
}

.el-table {
  width: 100%;
}

.el-pagination {
  margin-top: 20px;
  text-align: center;
}
</style>
