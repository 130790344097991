<template>
  <div class="ManagePermissions">
    <el-container>
      <el-header>
        <h1>管理权限组</h1>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <!-- 左侧：权限组列表 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="12" class="permission-table">
            <el-table ref="permissionTable" :data="permissionGroups" style="width: 100%;" :border="true">
              <el-table-column prop="promissID" label="权限组ID" min-width="100"></el-table-column>
              <el-table-column prop="name" label="权限组名称" min-width="150"></el-table-column>
              <el-table-column label="游戏列表" min-width="200">
                <template v-slot="scope">
                  <span>{{ getGameTitles(scope.row.gameids) }}</span> <!-- 显示游戏名称 -->
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="150">
                <template v-slot="scope">
                  <div class="table-buttons">
                    <el-button @click="openEditDialog(scope.row)" type="primary" size="small">编辑</el-button>
                    <el-button @click="deleteGroup(scope.row.promissID)" type="danger" size="small">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

          <!-- 右侧：表单 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="12" class="form-container">
            <el-form @submit.native.prevent="addPermissionGroup">
              <el-form-item label="权限组名称">
                <el-input v-model="newGroup.name"></el-input>
              </el-form-item>
              <el-form-item label="选择游戏">
                <el-button type="primary" @click="openGameSelector">选择游戏</el-button>
                <span v-if="newGroup.gameids.length">已选择游戏: {{ getGameTitles(newGroup.gameids) }}</span>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" native-type="submit">添加</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-main>

      <!-- 编辑、游戏选择对话框 -->
      <el-dialog v-model="dialogVisible" title="编辑权限组" width="40%" @close="resetForm">
        <el-form :model="currentGroup" label-width="100px">
          <el-form-item label="权限组名称">
            <el-input v-model="currentGroup.name"></el-input>
          </el-form-item>
          <el-form-item label="选择游戏">
            <el-button type="primary" @click="openGameSelector(true)">选择游戏</el-button>
            <span v-if="currentGroup.gameids.length">已选择游戏: {{ getGameTitles(currentGroup.gameids) }}</span>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updatePermissionGroup">保存</el-button>
        </template>
      </el-dialog>

      <el-dialog v-model="gameSelectorVisible" title="选择游戏" width="50%">
        <el-checkbox-group v-model="selectedGameIDs">
          <el-checkbox v-for="game in allGames" :key="game.gameid" :label="game.gameid">
            {{ game.title }}
          </el-checkbox>
        </el-checkbox-group>
        <template #footer>
          <el-button @click="gameSelectorVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmGameSelection">确定</el-button>
        </template>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'ManagePermissions',
  data() {
    return {
      permissionGroups: [],
      newGroup: {
        name: '',
        gameids: []  // 存储游戏ID
      },
      allGames: [], // 所有游戏的列表
      dialogVisible: false,
      gameSelectorVisible: false,
      currentGroup: {
        promissID: '',
        name: '',
        gameids: []  // 存储游戏ID
      },
      selectedGameIDs: [] // 用于存储选择的游戏 ID
    };
  },
  methods: {
    async fetchPermissionGroups() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getPromissGroups', { token });
        if (response.data.code === 200) {
          this.permissionGroups = response.data.groups.map(group => ({
            ...group,
            gameids: group.gameid.split(',')  // 保留原始的 gameids
          }));
          this.$nextTick(() => {
            this.$refs.permissionTable?.doLayout(); // 重新布局表格
          });
        } else {
          ElMessage.error(response.data.message || '获取权限组列表失败');
        }
      } catch (error) {
        console.error('Error fetching permission groups:', error.response || error);
        ElMessage.error('获取权限组列表失败');
      }
    },
    async fetchAllGames() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getGames_make', { token });
        if (response.data.code === 200) {
          this.allGames = response.data.games;
        } else {
          ElMessage.error(response.data.message || '获取游戏列表失败');
        }
      } catch (error) {
        console.error('Error fetching all games:', error.response || error);
        ElMessage.error('获取游戏列表失败');
      }
    },
    openGameSelector(isEdit = false) {
      this.gameSelectorVisible = true;
      this.selectedGameIDs = isEdit ? [...this.currentGroup.gameids] : [...this.newGroup.gameids];
    },
    confirmGameSelection() {
      if (this.dialogVisible) {
        this.currentGroup.gameids = [...this.selectedGameIDs];
      } else {
        this.newGroup.gameids = [...this.selectedGameIDs];
      }
      this.gameSelectorVisible = false;
    },
    getGameTitles(gameids) {
      return gameids
        .map(gameid => {
          const game = this.allGames.find(g => g.gameid === gameid);
          return game ? game.title : gameid;
        })
        .join(', ');
    },
    async addPermissionGroup() {
      try {
        const token = localStorage.getItem('user-token');
        const gameids = this.newGroup.gameids.join(',');
        const response = await axios.post('/index.php?action=addPromissGroup', {
          token,
          name: this.newGroup.name,
          gameids
        });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          this.newGroup.name = '';
          this.newGroup.gameids = [];
          ElMessage.success('权限组添加成功');
        } else {
          ElMessage.error(response.data.message || '添加权限组失败');
        }
      } catch (error) {
        console.error('Error adding permission group:', error.response || error);
        ElMessage.error('添加权限组失败');
      }
    },
    async deleteGroup(promissID) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deletePromissGroup', { token, promissID });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          ElMessage.success('权限组删除成功');
        } else {
          ElMessage.error(response.data.message || '删除权限组失败');
        }
      } catch (error) {
        console.error('Error deleting permission group:', error.response || error);
        ElMessage.error('删除权限组失败');
      }
    },
    openEditDialog(group) {
      this.currentGroup = { ...group, gameids: [...group.gameids] };
      this.selectedGameIDs = [...this.currentGroup.gameids];
      this.dialogVisible = true;
    },
    async updatePermissionGroup() {
      try {
        const token = localStorage.getItem('user-token');
        const gameids = this.currentGroup.gameids.join(',');
        const response = await axios.post('/index.php?action=updatePromissGroup', {
          token,
          promissID: this.currentGroup.promissID,
          name: this.currentGroup.name,
          gameids
        });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          this.dialogVisible = false;
          ElMessage.success('权限组更新成功');
        } else {
          ElMessage.error(response.data.message || '更新权限组失败');
        }
      } catch (error) {
        console.error('Error updating permission group:', error.response || error);
        ElMessage.error('更新权限组失败');
      }
    },
    resetForm() {
      this.currentGroup = {
        promissID: '',
        name: '',
        gameids: []
      };
    }
  },
  mounted() {
    this.fetchPermissionGroups();
    this.fetchAllGames();
  }
};
</script>

<style scoped>
.permission-table {
  min-height: 200px;
}

.form-container {
  margin-top: 20px;
}

.table-buttons {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .permission-table,
  .form-container {
    margin-top: 10px;
  }
}
</style>
