<template>
  <div class="DepotsManagement">
    <el-container>
      <el-header>
        <div class="header-container">
          <h1>支付订单管理</h1>
          <el-button
            type="danger"
            size="mini"
            class="clear-button"
            @click="clearUnpaidOrders"
          >
            清空未支付订单
          </el-button>
        </div>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索订单号或用户"
          @input="fetchDepotsAndKeys"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px;"
        ></el-input>
        <el-table :data="combinedData" v-if="combinedData.length > 0" style="width: 100%;">
          <el-table-column prop="OrderID" label="订单号" width="175"></el-table-column>
          <el-table-column prop="PayOrderID" label="支付单号" width="255"></el-table-column>
          <el-table-column prop="BuyTheSTH" label="购买事项" width="275"></el-table-column>
          <el-table-column prop="OrderTime" label="下单时间" width="175"></el-table-column>
          <el-table-column prop="PayTime" label="支付时间" width="175"></el-table-column>
          <el-table-column prop="Price" label="订单金额" width="100"></el-table-column>
          <el-table-column prop="UserName" label="下单用户" width="125"></el-table-column>
          <el-table-column prop="OrderIP" label="下单IP" width="145"></el-table-column>
          <el-table-column
            label="支付方式"
            width="100"
          >
            <template v-slot="scope">
              <el-tag :type="getPayType(scope.row.PayType).type" effect="light">
                {{ getPayType(scope.row.PayType).text }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="支付状态"
            width="100"
          >
            <template v-slot="scope">
              <el-tag :type="getTypes(scope.row.Types).type" effect="light">
                {{ getTypes(scope.row.Types).text }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty description="没有数据可显示"></el-empty>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'ManageOrder',
  data() {
    return {
      combinedData: [],
      searchQuery: '',
      currentDepot: {
        OrderID: '',
        PayOrderID: '',
        OrderTime: '',
        PayTime: '',
        Price: '',
        UserName: '',
        OrderIP: '',
        PayType: '',
        BuyTheSTH: '',
        Types: ''
      },
      bulkAddText: '',
      uploadHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('user-token')}`
      },
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.fetchDepotsAndKeys();
  },
  methods: {
    async fetchDepotsAndKeys() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getOrders', {
          token,
          search: this.searchQuery,
          page: this.currentPage,
          size: this.pageSize
        });
        if (response.data.code === 200) {
          this.combinedData = response.data.keys || [];
          this.total = parseInt(response.data.total, 10) || 0;
        } else {
          this.combinedData = [];
          this.total = 0;
          ElMessage.error(response.data.message || '获取数据失败');
        }
      } catch (error) {
        console.error('Error fetching depots and keys:', error);
        ElMessage.error('获取数据失败，请稍后重试');
      }
    },
    async clearUnpaidOrders() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=clearUnpaidOrders', {
          token
        });
        if (response.data.code === 200) {
          ElMessage.success('未支付订单已清空');
          this.fetchDepotsAndKeys();
        } else {
          ElMessage.error(response.data.message || '清空未支付订单失败');
        }
      } catch (error) {
        console.error('Error clearing unpaid orders:', error);
        ElMessage.error('清空未支付订单失败，请稍后重试');
      }
    },
    getPayType(value) {
      const payTypes = {
        0: { text: '余额', type: 'warning' },
        1: { text: '微信', type: 'success' },
        2: { text: '支付宝', type: 'info' }
      };
      return payTypes[value] || {};
    },
    getTypes(value) {
      const types = {
        0: { text: '待支付', type: 'warning' },
        1: { text: '已过期', type: 'danger' },
        2: { text: '已支付', type: 'success' },
        3: { text: '投诉中', type: 'danger' }
      };
      return types[value] || {};
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchDepotsAndKeys();
    }
  }
};
</script>

<style scoped>
.DepotsManagement {
  padding: 20px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear-button {
  margin-left: auto;
}
</style>
